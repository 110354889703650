import { configureStore } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
import { ioonsApi } from './service/ioonsService';
import { fastApi } from './service/fastApiService';
import rootReducer from './reducers/rootReducer';

// Still we don't use this, but we will in the near future.
// TODO: Make sure to use this in the future
const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(save()).concat(ioonsApi.middleware).concat(fastApi.middleware),
	preloadedState: load(),
});
export default store;
