import { applyMiddleware, createStore } from 'redux';
import { load, save } from 'redux-localstorage-simple';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer.js';
import { fastApi } from './service/fastApiService.js';
import { ioonsApi } from './service/ioonsService.js';

const store = createStore(
	rootReducer, load(),
	composeWithDevTools(applyMiddleware(fastApi.middleware, ioonsApi.middleware, thunk, save())),
);

export default store;
