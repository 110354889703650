import PropTypes from 'prop-types';
import React, { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { loadLanguages, multilanguage } from 'redux-multilanguage';
import { connect, useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TagManager from 'react-gtm-module/dist/TagManager.js';
import config from 'react-global-configuration';
import * as Sentry from '@sentry/react';
import { APP_VERSION } from './helpers/session.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import ScrollToTop from './helpers/scroll-top';
import translationsRo from './assets/i18n_ioons/translations_ro.json';
import translationsDe from './assets/i18n_ioons/translations_de.json';
import { useGetConfigQuery } from './redux/service/ioonsService';
import useLocalRedux from './helpers/useLocalRedux';
import useLocaleConfigSelectors from './helpers/useLocaleConfigSelectors';
import { useZenDesk } from './helpers/UseZenDesk';
import { useGetSystemAllQuery } from './redux/service/fastApiService';
import TokenProvider from './TokenProvider';
import NestedRouter from './NestedRouter';
import { setCountry } from './redux/slices/countrySlice.js';
import config_src, { validateConfig } from './config/config.js';
import LoadingStatus from './components/LoadingStatus';
import { setEmpty } from './redux/slices/userSlice';
import useCategoriesInRTK from './hooks/useCategoriesInRTK';
import { resetAppState, setAppVersion, setLastLocation, setShowNewVersionToast } from './redux/slices/appSlice';
import useWindowSize from './helpers/useWindowSize';
import { useWhyDidYouUpdate } from 'ahooks';

// home pages
const IOONSHOME = lazy(() => import("./pages/home/IOONS_HOME"));
// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
const ShopGridNoSidebar = lazy(() => import("./pages/shop/ShopGridNoSidebar"));
// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() =>
    import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
    import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
    import("./pages/shop-product/ProductFixedImage")
);

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));

// other pages
const AdventCalendar = lazy(() => import("./pages/other/AdventCalendar/AdventCalendar"));
const About = lazy(() => import("./pages/other/About"));
const Bezahlen = lazy(() => import("./pages/other/Bezahlen"));
const Lieferung = lazy(() => import("./pages/other/Lieferung"));
const Datenschutz = lazy(() => import("./pages/other/Datenschutz"));
const Widerrufsrecht = lazy(() => import("./pages/other/Widerrufsrecht"));
const AGB = lazy(() => import("./pages/other/AGB"));
const FAQ = lazy(() => import("./pages/other/Faq"));
const Presse = lazy(() => import("./pages/other/Presse"));
const Impressum = lazy(() => import("./pages/other/Impressum"));
const Versandkosten = lazy(() => import("./pages/other/Versandkosten"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Newsletter = lazy(() => import("./pages/other/Newsletter"));
const NewsletterUnsubscribe = lazy(() => import("./pages/other/NewsletterUnsubscribe"));
const MyAccount = lazy(() => import("./pages/other/MyAccount/MyAccount"));
const MyOrder = lazy(() => import("./pages/other/MyAccount/MyOrder"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const OrderPlaced = lazy(() => import("./pages/other/OrderPlaced"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const ElasticSearchPage = lazy(() => import("./pages/shop/ElasticSearchPage"));
const AbandonedCart = lazy(() => import("./pages/other/AbandonedCart"));

// const language = navigator.language.split("-")[0];
const language = config.get('language');
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            de: {
                translation: translationsDe
            },
            ro: {
                translation: translationsRo
            }
        },
        lng: language, // if you're using a language detector, do not define the lng option
        // fallbackLng: "en",
        // debug: true,
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

function App(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	useLocalRedux();
	useZenDesk();
	const {
		getWiderrufsformularPdfLink,
		getCurrencyCode,
		getCurrencySymbol,
	} = useLocaleConfigSelectors();
	const { version: appVersion, showNewVersionToast, lastLocation } = useSelector((state) => state.app);
	const handleToast = () => t('Our website has been updated');

		function invalidateStore() {
			dispatch(setLastLocation(window.location.pathname));
			dispatch(setEmpty());
			dispatch(resetAppState());
			localStorage.clear();
			window.location.reload();
		}
	
		useEffect(() => {
			if (getCurrencyCode !== null || getCurrencySymbol !== null) {
				const currencyInfo = {
					currencySymbol: getCurrencySymbol,
					currencyName: getCurrencyCode,
					currencyRate: 1,
				};
	
				dispatch({
					type: 'SET_CURRENCY',
					payload: currencyInfo,
				});
			}
		}, [getCurrencyCode, getCurrencySymbol]);
		const [width] = useWindowSize();
    const tagManagerArgs = {
        gtmId: config.get('gtmId')
    }
    const { data: configApi, isSuccess } = useGetConfigQuery({}, {});
    const [displayAdvent, setDisplayAdvent] = useState(0);
    useEffect(() => {
        if (isSuccess) {
            if (configApi.displayAdvent) {
                setDisplayAdvent(configApi.displayAdvent);
            }
        }
    }, [configApi]);

	useEffect(() => {
		if (appVersion !== APP_VERSION) {
			dispatch(setAppVersion(APP_VERSION));
			
			const timer = setTimeout(() => {
				invalidateStore();
			}, 0);
			return () => {
				clearTimeout(timer);
				toast.dismiss(toastId);
			};
		}

		let toastId = null;
		if (showNewVersionToast) {
			const toastMessage = handleToast();
			toastId = toast.info(
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<p style={{ marginRight: '10px', marginBottom: 0 }}>{toastMessage}</p>
					<button
						className="update-version-button theme-color-hover-background-hover"
						onClick={() => {
							toast.dismiss(toastId);
						}}
					>
						OK
					</button>
				</div>,
				{
					pauseOnHover: false,
					draggable: false,
					pauseOnFocusLoss: false,
					closeOnClick: false,
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 15000,
				}
			);
			dispatch(setShowNewVersionToast(false));
		}

		TagManager.initialize(tagManagerArgs);

		props.dispatch(
			loadLanguages({
				languages: {
					de: require('./assets/i18n_ioons/translations_de.json'),
					ro: require('./assets/i18n_ioons/translations_ro.json'),
				},
			})
		);
		
		const validConfig = validateConfig(config_src)
		if(!validConfig.isValid) {
			console.error('Some required config properties are missing!');
			alert(`This are missing from config.js: ${validConfig?.missingConfigs.join(', ')}`);
		}
	}, []);

	const checkIfClassExists = () => {
		const element = document.querySelector('.popup-google-content-cookie-consent');
		if (element) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	};
    return (
      <TokenProvider>
      <ToastProvider placement={width > 767 ? "top-right" : "top-center"}>
            <BreadcrumbsProvider>
                <Router>
                    <ScrollToTop>
                        <Suspense fallback={<LoadingStatus/>}>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/`}
                                    component={IOONSHOME}
                                />

                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/advent-calendar`}
                                    component={displayAdvent == 1 ? AdventCalendar : NotFound}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/shop-grid-standard/:type`}
                                    component={ShopGridStandard}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/shop-grid-no-sidebar/:type`}
                                    component={ShopGridNoSidebar}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product/:id`}
                                    render={(routeProps) => (
                                        <Product {...routeProps} key={routeProps.match.params.id} />
                                    )}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-tab-left/:id`}
                                    component={ProductTabLeft}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-tab-right/:id`}
                                    component={ProductTabRight}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-sticky/:id`}
                                    component={ProductSticky}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-slider/:id`}
                                    component={ProductSlider}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/product-fixed-image/:id`}
                                    component={ProductFixedImage}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/blog`}
                                    component={BlogStandard}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/about`}
                                    component={About}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/bezahlen`}
                                    component={Bezahlen}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/lieferung`}
                                    component={Lieferung}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/datenschutz`}
                                    component={Datenschutz}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/agb.html`}
                                    component={AGB}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/faq`}
                                    component={FAQ}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/presse`}
                                    component={Presse}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/Impressum`}
                                    component={Impressum}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/widerrufsrecht`}
                                    component={Widerrufsrecht}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/contact`}
                                    component={Contact}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/newsletter`}
                                    component={Newsletter}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/newsletter-unsubscribe`}
                                    component={NewsletterUnsubscribe}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/account/order/:id`}
                                    component={MyOrder}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/account`}
                                    component={MyAccount}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/guest/invoice/:ref/:hash`}
                                    component={MyOrder}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/versandkosten`}
                                    component={Versandkosten}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/login-register`}
                                    component={LoginRegister}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/forgot-password`}
                                    component={ForgotPassword}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/cart`}
                                    component={Cart}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/wishlist`}
                                    component={Wishlist}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/compare`}
                                    component={Compare}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/checkout`}
                                    component={Checkout}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/order-placed`}
                                    component={OrderPlaced}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/order-failed`}
                                    component={OrderFailed}
                                />

                                <Route
                                    path="/widerrufsformular.pdf"
                                    render={() => { window.location.href = getWiderrufsformularPdfLink }}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/not-found`}
                                    component={NotFound}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/search/:value`}
                                    component={ElasticSearchPage}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/back-to-cart/:token`}
                                    component={AbandonedCart}
                                />

                                <Route path={`${process.env.PUBLIC_URL}/*`}
                                    component={NestedRouter}
                                />
                            </Switch>
                        </Suspense>
                    </ScrollToTop>
                </Router>
            </BreadcrumbsProvider>
            <ToastContainer />
        </ToastProvider>
        </TokenProvider>
    );
};

App.propTypes = {
    dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
