import { createSlice } from "@reduxjs/toolkit";
import { APP_VERSION } from "../../helpers/session";

const appSlice = createSlice({
	name: "app",
	initialState: {
		version: APP_VERSION,
		showNewVersionToast: false,
		lastLocation: "/",
		categories_id_list: [],
	},
	reducers: {
		setCategoriesIdList: (state, action) => {
			state.categories_id_list = action.payload;
		},
		setAppVersion: (state, action) => {
			state.version = action.payload;
		},
		setShowNewVersionToast: (state, action) => {
			state.showNewVersionToast = action.payload;
		},
		setLastLocation: (state, action) => {
			state.lastLocation = action.payload;
		},
		resetAppState: (state) => {
			state.version = APP_VERSION;
			state.showNewVersionToast = true;
			// We don't reset lastLocation here as it might be needed for navigation
		},
	},
});

export const {
	setAppVersion,
	setShowNewVersionToast,
	setLastLocation,
	resetAppState,
	setCategoriesIdList,
} = appSlice.actions;

export default appSlice.reducer;
