const localStorageConstants = {
    // The token used for authentication
    token: 'jwtToken',
    // Check if the user is an admin
    isAdmin: 'isAdmin',
    // Check if the user is a business user
    isCompany: 'isCompany',
    // Check if we have to exclude VAT (Value Added Tax)
    excludeVAT: 'excludeVAT',
		// This is outdated: ProfileName is not used anymore from localstorage.
		// Soon will be deleted.
    profileName: 'profileName',
    appliedCoupon: 'appliedCoupon',
    customer_id: 'customer_id',
    address_id: 'address_id',
    user_identifier: 'user_identifier',
    country_id: 'country_id',
    country_tax: 'country_tax',
    tax_title: 'tax_title',
    country_tax_title: 'tax_title',
    lastLocation: 'lastLocation',
    salesBarVisible: 'salesBarVisible',
    user_express: 'user_express',
    user_express_data: 'user_express_data',
};

export default localStorageConstants;
