import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			let item = window.localStorage.getItem(key);
			if (item){
				item = JSON.stringify(item);
			}
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.error(`Error parsing localStorage item "${key}":`, error);
			return initialValue;
		}
	});
	
	const setValue = (value) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.error(`Error setting localStorage item "${key}":`, error);
		}
	};
	
	const removeValue = () => {
		try {
			window.localStorage.removeItem(key);
			setStoredValue(initialValue);
		} catch (error) {
			console.error(`Error removing localStorage item "${key}":`, error);
		}
	};
	
	return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
